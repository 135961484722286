$recording-list-screen-bottom-bar-button-size: 3rem !default;
$recording-list-screen-canvas-container-height: 20rem !default;
$recording-list-screen-canvas-border-radius: 1rem !default;

.recording-list-screen {
  .canvas-container {
    background-color: $gray-200;
    height: $recording-list-screen-canvas-container-height;
    border-radius: $recording-list-screen-canvas-border-radius;
    margin-bottom: $spacer;
    canvas {
      width: 100%;
    }
  }
  .button {
    width: $recording-list-screen-bottom-bar-button-size;
    height: $recording-list-screen-bottom-bar-button-size;
    border-radius: $recording-list-screen-bottom-bar-button-size;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      background-color: $gray-300;
    }

    span {
      font-size: $recording-list-screen-bottom-bar-button-size * 0.5;
    }
  }
  &-bottom-bar {
  }
}

$record-button-size: 4rem !default;
$record-button-contents-size: $record-button-size * 0.7 !default;
.record-button {
  width: $record-button-size;
  height: $record-button-size;
  border-radius: $record-button-size;
  background-color: $gray-400;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &-contents {
    background-color: $red;
    width: $record-button-contents-size;
    height: $record-button-contents-size;
    border-radius: $record-button-contents-size;
  }
}
