$recording-screen-play-button-size: 3rem !default;

.recording-screen {
  .canvas-container {
    canvas {
      width: 100%;
      background-color: $gray-300;
      border-radius: 1rem;
    }
    position: relative;
    .duration {
      border-radius: 1rem;
      padding: $spacer * 0.125 $spacer * 0.75;
      position: absolute;
      background-color: $gray-500;
      bottom: $spacer;
      right: $spacer;
    }
  }
}

.recording-screen-play-button {
  border-radius: $recording-screen-play-button-size;
  width: $recording-screen-play-button-size;
  height: $recording-screen-play-button-size;
  display: flex;
  justify-content: center;
  background-color: $gray-400;
  align-items: center;
}
